var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "操作说明",
        visible: _vm.dialogVisible,
        width: "750px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.ok,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("p", { staticClass: "title" }, [_vm._v("表体说明：")]),
        _c("p", [
          _vm._v(
            " 1.【数量】【单价】【备注】列可按箭头 [← → ↑ ↓ ] 键盘进行上下左右移动光标 "
          ),
        ]),
        _vm.type === "Receivables"
          ? _c("p", [
              _vm._v(
                " 2.【费用项】列只能通过按 [Tab] 键移动光标到下一个输入框，按 [Shift + Tab] 可回到上一个输入框 "
              ),
            ])
          : _c("p", [
              _vm._v(
                " 2.【费用项】【派送方式】列只能通过按 [Tab] 键移动光标到下一个输入框，按 [Shift + Tab] 可回到上一个输入框 "
              ),
            ]),
        _c("p", [_vm._v(" 3.最后一行【备注】列按 [Tab] 键新增一行可填账单 ")]),
        _vm.type === "Receivables"
          ? _c("p", [_vm._v("4.【费用项】列在输入的时候会默认选中第一个")])
          : _c("p", [
              _vm._v("4.【费用项】【派送方式】列在输入的时候会默认选中第一个"),
            ]),
        _vm.type === "Receivables"
          ? _c("p", [
              _vm._v("5.选择某一行后点击【提交】的账单状态会变成已审核"),
            ])
          : _vm._e(),
        _c("p", { staticClass: "title" }, [_vm._v("按钮说明：")]),
        _vm.type === "Receivables"
          ? _c("p", [_vm._v("1.【提交】：新增、更新、审核账单")])
          : _c("p", [_vm._v("1.【提交】：新增、更新账单")]),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "ok", on: { click: _vm.ok } }, [
            _vm._v("我知道了"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }