<template>
  <el-dialog
    v-drag
    title="操作说明"
    :visible.sync="dialogVisible"
    width="750px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="ok"
  >
    <div class="container">
      <p class="title">表体说明：</p>
      <p>
        1.【数量】【单价】【备注】列可按箭头 [← → ↑ ↓ ] 键盘进行上下左右移动光标
      </p>
      <p v-if="type === 'Receivables'">
        2.【费用项】列只能通过按 [Tab] 键移动光标到下一个输入框，按 [Shift +
        Tab] 可回到上一个输入框
      </p>
      <p v-else>
        2.【费用项】【派送方式】列只能通过按 [Tab] 键移动光标到下一个输入框，按 [Shift +
        Tab] 可回到上一个输入框
      </p>
      <p>
        3.最后一行【备注】列按 [Tab]
        键新增一行可填账单
      </p>
      <p v-if="type === 'Receivables'">4.【费用项】列在输入的时候会默认选中第一个</p>
      <p v-else>4.【费用项】【派送方式】列在输入的时候会默认选中第一个</p>
      <p v-if="type === 'Receivables'">5.选择某一行后点击【提交】的账单状态会变成已审核</p>
      <p class="title">按钮说明：</p>
      <p v-if="type === 'Receivables'">1.【提交】：新增、更新、审核账单</p>
      <p v-else>1.【提交】：新增、更新账单</p>
    </div>
    <span slot="footer" class="footers">
      <div class="ok" @click="ok">我知道了</div>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'Receivables'
    }
  },
  methods: {
    ok() {
      this.$emit('ok')
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 630px;
  height: 539px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  .title {
    color: rgba(254, 69, 61, 1);
    font-weight: Bold;
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/deep/.el-dialog__body {
  padding: 0 60px !important;
}
</style>
